<template>
  <el-row :class="{'margintop': margintop}" type="flex" justify="center">
    <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
  </el-row>
</template>

<script>
export default {
  name: 'GoBack',
  components: {},
  props: {
    margintop: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: '返回'
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    cancle() {
      // this.$router.push({ path: '/order-manage/Self-delivery-order' })
      this.$router.go(-1)
      // console.log(this.$router.go(-1))
    }
  }
}
</script>
<style lang="scss" scope>
.margintop {
  margin-top: 20px;
}
</style>
