<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="16" class="bg-purple-title">发运单基本信息</el-col>
    </el-row>
    <el-form label-width="160px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="发运单号:">
              {{ DetailsDatas.transportCode }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库单号:" :class="$i18n.locale">
              <span style="word-wrap: break-word;">
                {{ DetailsDatas.outboundCode }}
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="关联单号:" :class="$i18n.locale">
              <span style="word-wrap: break-word;">
                {{ DetailsDatas.orderId }}
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="跟踪号:" :class="$i18n.locale">
              {{ DetailsDatas.trackingNumber }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.outboundWH')+':'" :class="$i18n.locale">
              {{ DetailsDatas.outboundWarehouseName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.DestinationWarehouse')+':'" :class="$i18n.locale">
              {{ DetailsDatas.destinationWarehouseName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发运数量:" :class="$i18n.locale">
              {{ DetailsDatas.actualPairs }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发运重量:" :class="$i18n.locale">
              {{ DetailsDatas.actualWeight }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发运体积:" :class="$i18n.locale">
              {{ DetailsDatas.actualVolume }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发运时间:" :class="$i18n.locale">
              {{ DetailsDatas.transportTime }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态:" :class="$i18n.locale">
              {{ getShipmentStatus(DetailsDatas.transportStatus) }}</el-form-item>
          </el-col>

        </el-row>

        <el-row class="grid-content bg-purple-dark">
          <el-col :span="12" class="bg-purple-title">发运明细
          </el-col>
        </el-row>
        <el-table
          ref="shipmentDetailTable"
          class="mb-3"
          size="mini"
          :data="shipmentDetailTable"
          :header-cell-style="{background:'#fafafa'}"
          max-height="550px"
        >
          <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            prop="sku"
            label="SKU"
            width="270"
            align="center"
            sortable
            :sort-by="['sku_no_size', 'sku_size_sort']"
          >
            <template
              slot-scope="scope"
            >{{ scope.row.sku_no_size ? `${scope.row.sku_no_size}-${scope.row.sku_size}` : scope.row.sku }}</template>
          </el-table-column>
          <el-table-column prop="fnsku" label="FNSKU" align="center" sortable />
          <el-table-column prop="upc" label="UPC" align="center" sortable />
          <el-table-column
            prop="style"
            :label="$t('page.Specifications')"
            align="center"
            :sortable="true"
            :sort-by="['style', 'color', 'sizeSort']"
          >
            <template slot-scope="scope">{{ scope.row.style }} / {{ scope.row.color }} / {{ scope.row.size }}</template>
          </el-table-column>
          <el-table-column prop="actualPairs" label="发运数量" align="center" sortable />
          <el-table-column prop="actualWeight" label="发运重量(KG)" align="center" sortable />
          <el-table-column prop="actualVolume" label="发运体积(CBM)" align="center" sortable />
        </el-table>
      </div>
    </el-form>
    <go-back />
  </div>
</template>

<script>
import { findTransportShipmentDetail } from '@/api/stock-out'
import { Mixin } from '@/mixin/mixin.js'
import GoBack from '@/components/GoBack'

export default {
  components: {
    GoBack
  },
  mixins: [Mixin],
  data() {
    return {
      DetailsDatas: {},
      shipmentDetailTable: []
    }
  },
  mounted() {
    const { row } = this.$route.query
    this.DetailsDatas = row && JSON.parse(row) || {}
    console.log('%c 🥗  this.DetailsDatas: ', 'font-size:20px;background-color: #4b4b4b;color:#fff;', this.DetailsDatas)
    if (!Object.keys(this.DetailsDatas).length) {
      return
    }
    this._findCollectDetail(this.DetailsDatas)
  },
  methods: {
    // 获取详情数据
    async _findCollectDetail(data) {
      const { outboundCode, transportCode, tableSuffix, remark } = data
      const params = { outboundCode, transportCode, tableSuffix, remark }
      const { datas } = await findTransportShipmentDetail(params)
      this.shipmentDetailTable = datas
    },
    getShipmentStatus(status) {
      switch (status + '') {
        case '1':
          return '已发运'
        default:
          return ''
      }
    }

  }
}
</script>
<style lang="scss" scope>

</style>
